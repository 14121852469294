import { functions } from '@/firebase';

const listCharges = functions.httpsCallable('listCharges');
const allCharges = functions.httpsCallable('allCharges');

const listStripeCharges = () =>
  listCharges()
    .then((result) => result.data)
    .catch((error) => console.error(error));

const getAllStripeCharges = () =>
  allCharges()
    .then((result: { data: any }) => result.data)
    .catch((error: any) => console.error(error));

export { listStripeCharges, getAllStripeCharges };
