





















import { Component, Vue } from 'vue-property-decorator';
import { listStripeCharges, getAllStripeCharges } from '@/firebase/models/stripe';
import { exportCSVFile } from '@/utils/csv';

@Component({})
export default class lmsShipping extends Vue {
  loading = true;
  exportProcessing = false;
  charges = [];

  exportStripe() {
    this.exportProcessing = true;
    getAllStripeCharges()
      .then((response: any) => exportCSVFile(response, 'stripeCharges'))
      .then(() => (this.exportProcessing = false));
  }

  created() {
    listStripeCharges()
      .then((data) => (this.charges = data.charges))
      .then(() => (this.loading = false));
  }
}
