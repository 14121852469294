import jsonExport from 'jsonexport';

export function exportCSVFile(items: any[], fileTitle: string) {
  const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  jsonExport(items, (err: any, csv: any) => {
    if (err) return console.error(err);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  });
}
